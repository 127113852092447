import { Navigate, useRoutes } from "react-router-dom";
import BaseLayout from "../layouts/BaseLayout";
import AuthLayout from "../layouts/AuthLayout";
import Profile from "../pages/Applicant/Profile/Profile";
import Discovery from "../pages/Discovery/Discovery";
import SettingTemplate from "../pages/SettingTemplate/SettingTemplate";
import ActivateAccount from "../pages/ActivateAccount/ActivateAccount";
import Transaction from "../pages/Transaction/Transaction";
import AllTransactions from "../pages/AllTransactions/AllTransactions";
import Drafts from "../pages/Drafts/Drafts";
import EditIssuerTemplate from "../pages/EditIssuerTemplate/EditIssuerTemplate";
import Categories from "../pages/Categories/Categories";
import AllCertificatesPerCategory from "../pages/AllCertificatesPerCategory/AllCertificatesPerCategory";
import CertificateDetails from "../pages/CertificateDetails/CertificateDetails";
import CertificateIssue from "../pages/CertificateIssue/CertificateIssue";
import ViewAllDrafts from "../components/ViewAllDrafts/ViewAllDrafts";
import BatchTransaction from "../pages/BatchTransaction/BatchTransaction";
import CredentialPreview from "../pages/CredentialPreview/CredentialPreview";
import Dashboard from "../pages/Dashboard/Dashboard";
import OrganizationPage from "../pages/OrganizationPage/OrganizationPage";
import AuthContext from "../store/auth-context";
import { useContext } from "react";
import ForgotPasswordAccount from "../pages/ForgotPasswordAccount/ForgotPasswordAccount";
import SuccessEmailIdentity from "../pages/EmailIdentity/SuccessEmailIdentity";
import FailedEmailIdentity from "../pages/EmailIdentity/FailedEmailIdentity";
import VerificationApprovals from "../pages/VerificationApprovals/VerificationApprovals";
import TransactionDetailsPage from "../pages/TransactionDetailsPage/TransactionDetailsPage";
const Router = () => {
  const authContext = useContext(AuthContext);
  const publicRoutes = [
    {
      path: "",
      element: authContext.isLoggedIn ? <Dashboard /> : <Discovery />,
    },
    { path: "/discovery", element: <Discovery /> },
    {
      path: "certificate/0/:credential_template_id",
      element: <CertificateDetails />,
    },
    { path: "categories", element: <Categories /> },
    {
      path: "certificates/:category_name/:category_id",
      element: <AllCertificatesPerCategory />,
    },
    { path: "users/activate/:uuid/:token", element: <ActivateAccount /> },
    {
      path: "users/update-password/:uuid/:token",
      element: authContext.isLoggedIn ? (
        <Dashboard />
      ) : (
        <ForgotPasswordAccount />
      ),
    },
    { path: "/organizations/:organizationCode", element: <OrganizationPage /> },
    {
      path: "/success-email-verify",
      element: <SuccessEmailIdentity />,
    },
    {
      path: "/failure-email-verify",
      element: <FailedEmailIdentity />,
    },
  ];
  const auditRoutes = [
    { path: "settings", element: <SettingTemplate /> },
    { path: "profile", element: <Profile /> },
    { path: "transaction/all", element: <AllTransactions /> }, //single transaction
    // { path: "transaction/:transaction_id", element: <Transaction /> }, //all transactions
    {
      path: "transaction/:transaction_id",
      element: <BatchTransaction />,
    }, //all transactions

    {
      path: "certificate/1/:credential_template_id/:issuer_credential_template_id",
      element: <CertificateIssue />,
    },
    {
      path: "certificate/0/:credential_template_id/:issuer_credential_template_id/edit",
      element: <EditIssuerTemplate />,
    },
    { path: "drafts", element: <Drafts /> },
    {
      path: "drafts/:issuer_credential_template_id/",
      element: <EditIssuerTemplate />,
    },
    {
      path: "drafts/timestamp/:maxLimit/:currentStartDay/:currentEndDay/:timestamp",
      element: <ViewAllDrafts />,
    },
    // { path: "applications", element: <Applications /> },
    // { path: "applicationreview/:id", element: <ApplicationReview /> },
    // { path: "reviewedapplications", element: <ReviewedApplications /> },
    { element: <Navigate to="/issuer/discover" />, index: true },

    {
      path: "approvals/all",
      element: <VerificationApprovals />,
    },
    {
      path: "approvals/:transaction_id",
      element: <TransactionDetailsPage />,
    },
    
  ];

  const routes = useRoutes([
    {
      path: "/issuer",
      element: <AuthLayout />,
      children: auditRoutes,
    },
    {
      path: "/",
      element: <BaseLayout />,
      children: publicRoutes,
    },
    {
      path: "/credentials/verify/:cert_hash",
      element: <CredentialPreview />, // Standalone route without BaseLayout
    },
    {
      path: "*",
      element: <Navigate to="/" />, // Redirect to the root path
    },
  ]);

  return routes;
};

export default Router;
