import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Eye, Download, ChevronLeft } from "lucide-react";
import Toast from "../../common/Toast/Toast";
import CfLoader from "../../common/CfLoader/CfLoader";
import EntityConstants from "../../configs/EntityConstants";
import VerificationApprovalModal from "../../common/VerificationApprovalModal/VerificationApprovalModal";
import VerificationApprovalCertificateModal from "../../common/VerificationApprovalCertificateModal/VerificationApprovalCertificateModal";
import { POST } from "../../service/CRUDService";

const TransactionDetailsPage = () => {
  const { transaction_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [currentTransactionId, setCurrentTransactionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(location.state?.status || "Approval Pending");

  const {
    verifier_org_name,
    verifier_name,
    programme_name,
    certificate_id,
    holder_email,
    holder_name,
    holder_phone_number,
    created_at,
    verifier_org_code,
    verifier_email,
    payment_status,
    verification_cost,
    payment_id,
    payment_date,
    mode_of_payment,
    document_type,
    mode_of_verification,
    uploaded_date,
    uploaded_document,
  } = location.state || {};

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    const [day, month, year] = formattedDate.split(" ");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day} ${month} '${year} ${hours}:${minutes}`;
  };


  const handleAccept = () => {
    setActionType('Valid');
    setIsModalOpen(true);
  };

  const handleReject = () => {
    setActionType('Rejected');
    setIsModalOpen(true);
  };

  const handleBackClick = () => {
    navigate("/issuer/approvals/all", {
      state: {
        filters: location.state?.filters
      }
    });
  };

  const handleConfirmAction = async (actionType) => {
    const body = {
      transaction_id: transaction_id,
      verification_approval_status: actionType,
    };

    try {
      const response = await POST(EntityConstants.GET_VERIFICATION_APPROVALS_LIST, body);
      if (response.ok) {
        Toast({
          type: "success",
          title: "Transaction Updated",
          message: `Transaction has been ${actionType === 'Valid' ? 'Approved' : 'Rejected'} successfully!`,
        });
        
        // Update the current status
        setCurrentStatus(actionType);
        
        // Navigate back to the list after a short delay
        setTimeout(() => {
          navigate("/issuer/approvals/all", {
            state: {
              filters: location.state?.filters
            }
          });
        }, 1500);
      } else {
        Toast({
          type: "error",
          title: "Error",
          message: "There was an error updating the transaction status.",
        });
      }
    } catch (error) {
      console.error("Error during the API request:", error);
      Toast({
        type: "error",
        title: "Error",
        message: "An error occurred while updating the transaction status.",
      });
    }
    setIsModalOpen(false);
  };

  const handleView = () => {
    if (!uploaded_document) {
      Toast({
        type: "error",
        title: "Error",
        message: "Certificate preview not available.",
      });
      return;
    }
    setIsOpen(true);
    setCurrentTransactionId(transaction_id);
  };

  const handleDownload = () => {
    if (!uploaded_document) {
      Toast({
        type: "error",
        title: "Error",
        message: "No document available to download.",
      });
      return;
    }
    const link = document.createElement('a');
    link.href = uploaded_document;
    link.download = "document";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isLoading) {
    return <CfLoader />;
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-24 pb-10">
      {/* Header */}
      <div className="max-w-8xl mx-auto px-4">
        <div className="flex items-center space-x-2 mb-6">
          <button
            onClick={handleBackClick}
            className="flex items-center text-gray-600 hover:text-gray-900"
          >
            <ChevronLeft className="w-4 h-4 mr-1" />
            <span>Verification approvals</span>
          </button>
          <span className="text-gray-400">/</span>
          <span className="text-gray-400">Transaction ID: #{transaction_id}</span>
        </div>

        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold text-gray-900">
            Transaction ID: #{transaction_id}
          </h1>
          
          {currentStatus === "Approval Pending" && (
            <div className="flex flex-wrap lg:flex-nowrap space-x-4 gap-4 lg:gap-0">
              <button
                onClick={handleAccept}
                className="flex rounded-[8px] lg:rounded-xl items-center justify-center gap-2 w-full lg:w-[10rem] h-[2.5rem] bg-cfPrimaryButton text-white font-medium lg:font-semibold text-sm lg:text-md"
              >
                Approve
              </button>
              <button
                onClick={handleReject}
                className="flex rounded-[8px] lg:rounded-xl items-center justify-center gap-2 w-full lg:w-[10rem] h-[2.5rem] bg-white text-black font-medium lg:font-semibold text-sm lg:text-md border border-border-[#000000]"
              >
                Reject
              </button>
            </div>
          )}
          
          {currentStatus !== "Approval Pending" && (
            <div
              className={`px-4 py-2 rounded-lg ${
                currentStatus === "Valid" ? "bg-cfPrimaryButton text-white" : "bg-red-500 text-white"
              }`}
            >
              <span className="text-white font-medium">
                Status: {currentStatus === 'Valid' ? 'Approved' : 'Rejected'}
              </span>
            </div>
          )}
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Left Column - Details */}
          <div className="lg:col-span-2 space-y-6">
            {/* Candidate Details Card */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Candidate Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div>
                    <label className="text-sm text-gray-500">Name</label>
                    <p className="text-gray-900">{holder_name || 'N/A'}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Certificate ID</label>
                    <p className="text-gray-900">{certificate_id || 'N/A'}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Phone</label>
                    <p className="text-gray-900">{holder_phone_number || 'N/A'}</p>
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm text-gray-500">Programme Name</label>
                    <p className="text-gray-900">{programme_name || 'N/A'}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Email</label>
                    <p className="text-gray-900">{holder_email || 'N/A'}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Verifier Organization Details Card */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Verifier Organization Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div>
                    <label className="text-sm text-gray-500">Verifier Organization</label>
                    <p className="text-gray-900">{verifier_org_name || 'N/A'}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Organization Code</label>
                    <p className="text-gray-900">{verifier_org_code || 'N/A'}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Verifier Email</label>
                    <p className="text-gray-900">{verifier_email || 'N/A'}</p>
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm text-gray-500">Created At</label>
                    <p className="text-gray-900">{formatDate(created_at)}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Verifier Name</label>
                    <p className="text-gray-900">{verifier_name || 'N/A'}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Amount Status</label>
                    <p className="text-gray-900">{payment_status || 'N/A'}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Payment Summary Card */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Payment Summary</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div>
                    <label className="text-sm text-gray-500">Amount Paid</label>
                    <p className="text-gray-900">
                    {verification_cost && parseFloat(verification_cost) !== 0
                        ? `₹ ${(parseFloat(verification_cost) / 100).toFixed(2)}`
                        : "N/A"}
                        </p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Paid At</label>
                    <p className="text-gray-900">{payment_date ? formatDate(payment_date) : 'N/A'}</p>
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm text-gray-500">Reference ID</label>
                    <p className="text-gray-900">{payment_id || 'N/A'}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500">Mode of Payment</label>
                    <p className="text-gray-900">{mode_of_payment || 'N/A'}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Document Preview */}
          <div className="lg:col-span-1">
            <div className="bg-white rounded-lg shadow-sm p-6 sticky top-24">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Verification Document</h2>
              <div className="space-y-4">
                <div>
                  <label className="text-sm text-gray-500">Document Type</label>
                  <p className="text-gray-900">{document_type || 'N/A'}</p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Verification Mode</label>
                  <p className="text-gray-900">{mode_of_verification || 'N/A'}</p>
                </div>
                <div>
                  <label className="text-sm text-gray-500">Uploaded At</label>
                  <p className="text-gray-900">{formatDate(uploaded_date )}</p>
                </div>

                <div className="mt-6">
                  <label className="text-sm text-gray-500 block mb-3">Document Preview</label>
                  <div className="aspect-square rounded-lg border-2 border-dashed border-gray-200 flex items-center justify-center mb-6">
                    {uploaded_document ? (
                      <img
                        src={uploaded_document}
                        alt="Document Preview"
                        className="max-w-full max-h-full object-contain"
                      />
                    ) : (
                      <p className="text-gray-400">No preview available</p>
                    )}
                  </div>
                </div>

                <div className="flex space-x-4">
                  <button
                    onClick={handleView}
                    className="flex-1 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
                  >
                    <Eye className="w-4 h-4 mr-2" />
                    View
                  </button>
                  <button
                    onClick={handleDownload}
                    className="flex-1 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
                  >
                    <Download className="w-4 h-4 mr-2" />
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modals */}
      <VerificationApprovalModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        actionType={actionType}
        handleConfirmAction={handleConfirmAction}
      />

      {isOpen && currentTransactionId === transaction_id && (
        <VerificationApprovalCertificateModal
          isOpen={isOpen}
          certificateData={uploaded_document}
          openCloseModal={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default TransactionDetailsPage;
