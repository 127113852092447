const ENV = 'app'
const AppConstants = {
  TENANT: { tenant: "Uganda" },
  ENTITY: { entity: "Exporter" },
  GOOGLE_CLIENT: { client_name: "google" },
  MICROSOFT_RESPONSE_TYPE: "code",
  MICROSOFT_RESPONSE_MODE: "query",
  MICROSOFT_SCOPE: "openid email profile offline_access User.Read",
  MICROSOFT_STATE: "12345",
  MICROSOFT_GRANT_TYPE: "authorization_code",
  MICROSOFT_CLIENT: "microsft",

  //Google Client ID
  CLIENT_ID:
    "229159744570-qbb5uc9a94vofkgemf23esodlmjqk30k.apps.googleusercontent.com",
  MICROSOFT_CLIENT_ID: "dc013428-01ae-4c09-8595-a03c1d379c9c",
  MICROSOFT_CLIENT_SECRET: "na_8Q~fEaLjnuH54VEZkJkWaWzUt26B9M1yjecRl",
  GOOGLE_CAPTCHA_SITE_KEY: "6LcTlWcqAAAAAB_FOO54EyyBMkvgghxU51aIw5Y8",
  GOOGLE_CAPTCHA_SECRET_KEY: "6LcTlWcqAAAAAIwVkzYN2h52mIf2H8MsErnjTJfI",
  HTTPS_HOST: `https://${ENV}.credissuer.com/`,
  HTTPS_HOST_API: `https://${ENV}.credissuer.com/api`,

  SENTRY_DSN_URL_DEV:
    "https://b512e2344caf727cd6d6fe33943c1b44@o4506120510636032.ingest.sentry.io/4506143497519104",
  SENTRY_DSN_URL_PROD:
    "https://5618d880c07b9f280b1889f340fbc318@o4506120510636032.ingest.sentry.io/4506235666628608",
  HOST: "localhost",
  HOST_REGEX: new RegExp(`^https://${ENV}.credissuer.com/api`),
};
export default AppConstants;
