import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function BulkVerificationApprovalModal({
  isModalOpen,
  toggleModal,
  actionType,
  handleConfirmAction,
  selectedTransactions, // Add this prop to receive selected transactions
}) {
  function closeModal() {
    toggleModal(null);
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    const [day, month, year] = formattedDate.split(" ");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day} ${month} '${year} ${hours}:${minutes}`;
  };

  return (
    <>
      <Transition appear show={isModalOpen ? true : false} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 backdrop-blur-md" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl h-auto transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative">
                  <button
                    type="button"
                    className="absolute top-3 right-3 text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    &#10005;
                  </button>

                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-medium leading-6 text-gray-900 mb-4"
                  >
                    Confirm Approvals
                  </Dialog.Title>

                  {/* Table for selected transactions */}
                  <div className="mt-4 max-h-96 overflow-y-auto">
                    {" "}
                    {/* Added scrolling */}
                    <table className="min-w-full mb-4 divide-y divide-gray-200">
                      <thead className="bg-[#E0EAFF] text-gray-900  p-4 h-14">
                        <tr>
                          <th className="px-4 py-2 text-center text-sm font-medium">
                            Verifier Name
                          </th>
                          <th className="px-4 py-2 text-center text-sm font-medium ">
                          <div className="flex flex-col items-center">
                              <span>Verifier</span>
                              <span>Organization</span>
                            </div>
                          </th>
                          <th className="px-4 py-2 text-center text-sm font-medium">
                            Created At
                          </th>
                          <th className="px-4 py-2 text-center text-sm font-medium">
                            Amount Paid
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {selectedTransactions.map((transaction) => (
                          <tr key={transaction.transaction_id}>
                            <td className="px-4 py-2 text-center">
                              {transaction.verifier_name}
                            </td>
                            <td className="px-4 py-2 text-center">
                              {transaction.verifier_org_name}
                            </td>
                            <td className="px-4 py-2 text-center">
                              {transaction.created_at  ? formatDate(transaction.created_at) : "No date available"}
                            </td>
                            <td className="px-4 py-2 text-center">
                            {transaction.verification_cost && parseFloat(transaction.verification_cost) !== 0
                                  ? `₹ ${(parseFloat(transaction.verification_cost) / 100).toFixed(2)}`
                                  : "N/A"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-4 flex justify-end gap-4">
                    {" "}
                    {/* Right-align buttons */}
                    <button
                      type="button"
                      onClick={closeModal}
                      className="py-2.5 px-5 ms-3 text-sm hover:bg-gray-200 font-medium text-gray-900 focus:outline-none bg-white rounded-[8px] border border-gray-200 focus:z-10 focus:ring-3 focus:ring-gray-100"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => handleConfirmAction(actionType)}
                      className={`flex items-center justify-center gap-2 w-[10rem] h-[2.5rem] lg:w-[10rem] lg:px-0 ${
                        actionType === "Valid"
                          ? "rounded-[8px] lg:rounded-xl bg-cfPrimaryButton text-white font-medium lg:font-semibold text-sm lg:text-md"
                          : "rounded-[8px] bg-[#D94D20] text-white font-medium lg:font-semibold text-sm lg:text-md"
                      }`}
                    >
                      {actionType === "Valid" ? "Approve" : "Reject"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
