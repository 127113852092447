import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import VerificationApprovalModal from "../../../common/VerificationApprovalModal/VerificationApprovalModal.jsx";

const VerificationApprovalsLongCard = (props) => {
  const {
    transaction_id,
    verifier_org_name,
    created_at,
    verification_cost,
    status,
    onCheckboxChange,
    isChecked,
    onRemoveTransaction,
    handleConfirmAction,
    verifier_name,
    programme_name,
    document_type,
    certificate_id,
    holder_email,
    holder_name,
    holder_phone_number,
    verifier_org_code,
    verifier_email,
    payment_status,
    payment_id,
    payment_date,
    mode_of_payment,
    mode_of_verification,
    uploaded_date,
    uploaded_document,
    onClick,
    selectedApprovalStatus,
  } = props;

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState("");

  const handleReject = (event) => {
    event.stopPropagation();
    setActionType("Rejected");
    setIsModalOpen(true); // Open the modal
  };

  const handleAccept = (event) => {
    event.stopPropagation();
    setActionType("Valid");
    setIsModalOpen(true);
  };

  const handleModalConfirm = () => {
    // Call handleConfirmAction with the action type and the transaction
    handleConfirmAction(actionType, transaction_id);
    setIsModalOpen(false);
  };

  const handleCardClick = (event) => {
    event.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  const handleCheckboxChange = (event) => {
    event.stopPropagation();
    onCheckboxChange(transaction_id, event.target.checked);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    const [day, month, year] = formattedDate.split(" ");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day} ${month} '${year} ${hours}:${minutes}`;
  };

  
  const getActionStatus = () => {
    if (selectedApprovalStatus === "Valid") {
      return <p className="font-semibold" style={{ color: "#3538CD" }}>Approved</p>;
    }
    if (selectedApprovalStatus === "Rejected") {
      return <p className="font-semibold" style={{ color: "#D94D20" }}>Rejected</p>;
    }
  
    // Default and Pending case: Show Accept and Reject buttons
    return (
      <>
        <button
          className="flex rounded-[8px] lg:rounded-xl items-center justify-center gap-2 w-[10rem] h-[2.5rem] lg:w-[10rem] lg:px-0 bg-cfPrimaryButton text-white font-medium lg:font-semibold text-sm lg:text-md"
          onClick={handleAccept}
        >
          <div>Approve</div>
        </button>
        <button
          className="flex rounded-[8px] lg:rounded-xl items-center justify-center gap-2 w-[10rem] h-[2.5rem] lg:w-[10rem] lg:px-0 bg-white text-black font-medium lg:font-semibold text-sm lg:text-md border border-border-[#000000]"
          onClick={handleReject}
        >
          <div>Reject</div>
        </button>
      </>
    );
  };
  

  return (
    <div
      className="flex bg-white items-center min-h-[5rem] border-b py-1 px-4 cursor-pointer "
      onClick={handleCardClick}
    >
      <div
        className="flex w-[2.0%] justify-center items-center bg-white"
        onClick={(e) => e.stopPropagation()}
      >
      {selectedApprovalStatus !== "Valid" && selectedApprovalStatus !== "Rejected" && (
        <input
          type="checkbox" // Ensure individual row state is tracked
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="w-5 h-5 border-2 border-gray-500 rounded-md accent-blue-600 appearance-auto cursor-pointer"
          onClick={(e) => e.stopPropagation()}
        />
      )}
      </div>
      <div className="flex w-[14.67%] justify-center items-center cursor-pointer ">
        <p className="font-regular text-black text-[14px]">{verifier_name}</p>
      </div>
      <div className="flex w-[16.67%] justify-center text-center cursor-pointer ">
        <p className="font-regular text-black text-[14px]">
          {verifier_org_name}
        </p>
      </div>
      <div className="flex w-[16.67%] justify-center items-center cursor-pointer">
        <p className="font-regular text-black text-[14px]">
          {created_at ? formatDate(created_at) : "No date available"}
        </p>
      </div>
      <div className="flex w-[16.67%] justify-center items-center cursor-pointer">
        <p className="font-regular text-black text-[14px]">
          {verification_cost && parseFloat(verification_cost) !== 0
            ? `₹ ${(parseFloat(verification_cost) / 100).toFixed(2)}`
            : "N/A"}
        </p>
      </div>
      <div className="flex w-[16.67%] justify-center items-center cursor-pointer">
        <p className="font-regular text-black text-[14px]">{document_type}</p>
      </div>
      <div className="flex w-[16.67%] justify-center items-center space-x-4">
         {getActionStatus()} 
      </div>

      {/* Confirmation Modal */}
      <VerificationApprovalModal
        isModalOpen={isModalOpen}
        toggleModal={setIsModalOpen}
        actionType={actionType}
        handleConfirmAction={handleModalConfirm}
      />
    </div>
  );
};

export default VerificationApprovalsLongCard;
