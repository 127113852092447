import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation, useParams } from "react-router-dom";
import EntityConstants from "../../configs/EntityConstants";
import { GET, POST } from "../../service/CRUDService";
import { imgRegex, whiteSpaceEmptyStrRegex } from "../../configs/App.Regex";
import { auth, Formio } from "@formio/react";
import Toast from "../../common/Toast/Toast";
import CfLoader from "../../common/CfLoader/CfLoader";
import { useNavigate } from "react-router-dom";

import { RxReload } from "react-icons/rx";

import { LuImageOff } from "react-icons/lu";

import CfBreadcrumb from "../../common/CfBreadcrumb/CfBreadcrumb";

import GenerateCertificate from "../../service/GenerateCertificate";
import AuthContext from "../../store/auth-context";

const CertificateDetails = () => {
  const navigate = useNavigate();

  const { credential_template_id, issuer_credential_template_id } = useParams();
  const [certificateTitle, setCertificateTitle] = useState("");
  const [error, setError] = useState("");
  const [fieldsChanged, setFieldsChanged] = useState({});

  const [currentSVG, setCurrentSVG] = useState("");
  const [certificateData, setCertificateData] = useState();
  const [orgLogo, setOrgLogo] = useState("");
  const [customFieldChanged, setCustomFieldChanged] = useState({});
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [activeTab, setActiveTab] = useState("basic");
  const [formData, setFormData] = useState({});
  const [certificateRedirectURL, setCertificateRedirectURL] = useState("");
  const [isAuthRequired, setIsAuthRequired] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isPaymentRequired, setIsPaymentRequired] = useState(false);
  const [verificationCost, setVerificationCost] = useState("");

  const handlePaymentCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    localStorage.setItem("verCheck", isChecked);
    setIsPaymentRequired(isChecked);

    if (!isChecked) {
      localStorage.removeItem("verCost");
      setVerificationCost("");
    }
  };

  const handleVerificationCostChange = (e) => {
    const rupees = e.target.value; // Keep it as a string
    const paisa = Math.round(parseFloat(rupees || 0) * 100); // Convert safely
    localStorage.setItem("verCost", paisa);
    setVerificationCost(rupees); // Keep rupees as a string for input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const certTitle = sessionStorage.getItem("certificate_title");
    const isEmpty = certTitle == null || certTitle === "";
    const isWhiteSpace = whiteSpaceEmptyStrRegex.test(certTitle);

    if (isEmpty || isWhiteSpace) {
      Toast({
        type: "error",
        title: "Error",
        message: "Please fill the required fields.",
      });
      return;
    }

    if (formRef.current) {
      const form = formRef.current;
      setLoading(true);
      try {
        await form.submit();
        console.log("Form submitted successfully!");
      } catch (err) {
        Toast({
          type: "error",
          title: "Error",
          message:
            "Error occurred during submission. Please fill the required fields.",
        });
        console.error("Form submission error:", err);
      } finally {
        setLoading(false);
      }
    }
  };

  const breadcrumbItems = [
    { title: "Edit", to: `/certificate/0/${credential_template_id}` },
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const location = useLocation();
  const authContext = useContext(AuthContext);

  const onSubmitHandle = async (sub, bool) => {
    //Submit-> only Preview for Submit form
    if (bool) {
      let payload = { ...sub };

      if (!payload.data.logo || payload.data.logo.length === 0) {
        //! If no logo is provided, use the org_logo from this API GET(EntityConstants.GET_ORG_DETAILS)
        if (orgLogo) {
          payload.data.logo = [
            {
              storage: "base64",
              name: "org_logo.svg",
              url: orgLogo,
              type: "image/svg+xml",
            },
          ];
        } else {
          try {
            const response = await GET(EntityConstants.GET_ORG_DETAILS);
            const data = await response.json();
            if (data.org_logo) {
              payload.data.logo = [
                {
                  storage: "base64",
                  name: "default_org_logo.svg",
                  url: data.org_logo,
                  type: "image/svg+xml",
                },
              ];
              Toast({
                type: "success",
                title: "Success",
                message: "Default logo applied.",
              });
            } else {
              Toast({
                type: "error",
                title: "Error",
                message: "API request failed. Please retry.",
              });
              return;
            }
          } catch (err) {
            Toast({
              type: "error",
              title: "Error",
              message: "API request failed. Please retry.",
            });
            return;
          }
        }
      }

      const certTitle = sessionStorage.getItem("certificate_title");
      const isCertTitleEmpty =
        !certTitle ||
        certTitle === "" ||
        whiteSpaceEmptyStrRegex.test(certTitle);
      const isVerificationAmountEmpty =
        localStorage.getItem("verCheck") &&
        (!localStorage.getItem("verCost") ||
          localStorage.getItem("verCost").trim() === "");

      if (isCertTitleEmpty || isVerificationAmountEmpty) {
        Toast({
          type: "error",
          title: "Error",
          message: "Please fill the required fields.",
        });
        return;
      }
      payload = {
        form_data: payload,
        credential_template_id: credential_template_id,
        certificate_name: sessionStorage.getItem("certificate_title"),
        certificate_redirect_url:
          sessionStorage.getItem("certificate_redirect_url") || undefined,
        is_auth_required:
          sessionStorage.getItem("is_auth_required") !== null
            ? JSON.parse(sessionStorage.getItem("is_auth_required"))
            : undefined,
        is_public:
          sessionStorage.getItem("is_public") !== null
            ? JSON.parse(sessionStorage.getItem("is_public"))
            : undefined,
        is_payment_required: localStorage.getItem("verCheck") === "true",
        verification_cost: localStorage.getItem("verCheck")
          ? localStorage.getItem("verCost")
          : null,
      };
      try {
        const response = await POST(
          `${EntityConstants.POST_CREDENTIALS_TEMPLATE_DESIGN}${credential_template_id}/design`,
          payload
        );
        if (response.ok) {
          const data = await response.json();
          sessionStorage.setItem(
            "issuer_credential_template_id",
            data.issuer_credential_template_id
          );
          const stringifyedSub = JSON.stringify(sub);
          sessionStorage.setItem("details_form_data", stringifyedSub);
          navigate(
            `/issuer/certificate/1/${data.credential_template_id}/${data.issuer_credential_template_id}`
          );
          Toast({
            type: "success",
            title: "Success",
            message: "Certificate Template Saved Successfully",
          });
        } else {
          const errorData = await response.json();
          if (errorData.message && Array.isArray(errorData.message)) {
            errorData.message.forEach((msg) => {
              Toast({
                type: "error",
                title: "Error",
                message: msg,
              });
            });
          } else {
            Toast({
              type: "error",
              title: "Error",
              message: "API request failed\nPlease retry...",
            });
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
        Toast({
          type: "error",
          title: "Error",
          message: error,
        });
      }
    } else {
      authContext.setTriggerLoginModuleToggleValue(true);
    }
  };

  const GET_CERTIFICATE_DATA = (credential_template_id) => {
    GET(
      `${EntityConstants.GET_CREDENTIALS_TEMPLATE_DESIGN}${credential_template_id}/design`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
        } else {
          setCertificateData(data);
          setFormData(data?.default_template_variables_data?.data || {});
        }
      });
  };

  const formFieldsChanged = (fields) => {
    if (fields?.data) {
      setFormData(fields.data);
      setFieldsChanged(fields.data);
    }
    if (
      fields.changed?.component?.key !== undefined &&
      fields.changed?.value !== undefined
    ) {
      const fieldKey = fields.changed.component.key;
      if (fieldKey === "logo") {
        const logoValue = fields.changed.value;
        if (!logoValue || logoValue.length === 0 || !logoValue[0]?.url) {
          setOrgLogo(orgLogo);
        } else if (imgRegex.test(logoValue[0].url)) {
          // If the logo is valid, update the orgLogo state
          setOrgLogo(logoValue[0].url);
        }
      }
      if (
        fields.changed?.value[0]?.type &&
        imgRegex.test(fields.changed?.value[0].url)
      ) {
        isToggleOn
          ? setFieldsChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value[0].url,
            }))
          : setCustomFieldChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value[0].url,
            }));
      } else {
        isToggleOn
          ? setFieldsChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value,
            }))
          : setCustomFieldChanged((prevData) => ({
              ...prevData,
              // Add your new key-value pair here
              [fields.changed?.component?.key]: fields.changed?.value,
            }));
      }
    } else if (fields?.data) {
      setFieldsChanged(fields.data);
    }
  };

  useEffect(() => {
    credential_template_id && GET_CERTIFICATE_DATA(credential_template_id);
  }, [credential_template_id]);

  const fetchSvg = async (certificateData) => {
    const { _1, template_variables_data, template_svg, _2 } = certificateData;
    try {
      const response = await fetch(`${template_svg}`);
      // const response = await fetch(`${template_svg}`);
      if (response.ok) {
        const svgContent = await response.text();
        setCurrentSVG(svgContent);
        setFieldsChanged(template_variables_data);
        //!injecting the svg content into the container
        const container = document.getElementById("certificate_container");
        container.style.filter = "drop-shadow(0 0 2px rgba(0, 0, 0, 0.264))";
        container.innerHTML = svgContent;
      } else if (response.status == 404) {
        const container = document.getElementById("certificate_container");
        ReactDOM.render(
          <div class="text-black flex justify-center w-full h-full text-lg">
            <div className="flex flex-col items-center gap-2 w-fit h-fit">
              <span className="flex items-center gap-2 w-fit h-fit  bg-gray-100 px-2 py-1 rounded-lg">
                <p>Coulden't find image..</p>
                <LuImageOff class="text-cfPrimaryCardHover " />
              </span>
              <span>
                <p>Please create template again...</p>
              </span>
            </div>
          </div>,
          container
        );
      } else {
        const container = document.getElementById("certificate_container");
        ReactDOM.render(
          <div class="text-black flex justify-center w-full h-full text-lg">
            <div
              className="flex items-center gap-2 w-fit h-fit rounded-lg cursor-pointer"
              onClick={() => {
                ReactDOM.render(
                  <span className="w-full flex justify-center">
                    <CfLoader />
                  </span>,
                  container
                );
                certificateData && fetchSvg(certificateData);
              }}
            >
              <p>Couldn't Load Image...</p>
              <RxReload class="text-cfPrimaryCardHover " />
            </div>
          </div>,
          container
        );
      }
    } catch (error) {
      console.error("Error loading SVG:", error);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("verCost");
      localStorage.removeItem("verCheck");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      localStorage.removeItem("verCost");
      localStorage.removeItem("verCheck");
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (authContext.isLoggedIn) {
      GET(EntityConstants.GET_ORG_DETAILS)
        .then((response) => response.json())
        .then((data) => {
          setOrgLogo(data.org_logo);
        })
        .catch((error) => {
          console.log("Error in GET_ORG_DETAILS : ", error);
          Toast({
            type: "error",
            title: "Error",
            message: "API request failed...",
          });
        });
    }
  }, [authContext.isLoggedIn]);

  useEffect(() => {
    certificateData instanceof Object &&
      certificateData.hasOwnProperty("default_template_variables_data") &&
      fetchSvg(certificateData);
  }, [certificateData]);

  useEffect(() => {
    if (currentSVG) {
      const updatedFields = isToggleOn
        ? { ...fieldsChanged, logo: orgLogo }
        : { ...customFieldChanged, logo: orgLogo };

      const modifiedSVG = GenerateCertificate(currentSVG, updatedFields);
      document.getElementById("certificate_container").innerHTML = modifiedSVG;
    }
  }, [fieldsChanged, customFieldChanged, currentSVG, orgLogo, isToggleOn]);

  useEffect(() => {
    if (certificateTitle.trim().length === 0) {
      setError("Certificate Name is required");
      sessionStorage.removeItem("certificate_title");
    } else {
      sessionStorage.setItem("certificate_title", certificateTitle);
      setError("");
    }
  }, [certificateTitle]);

  useEffect(() => {
    if (
      certificateRedirectURL.length === 0 ||
      whiteSpaceEmptyStrRegex.test(certificateRedirectURL)
    ) {
      sessionStorage.removeItem("certificate_redirect_url");
    } else {
      sessionStorage.setItem(
        "certificate_redirect_url",
        certificateRedirectURL
      );
    }
  }, [certificateRedirectURL]);

  useEffect(() => {
    sessionStorage.setItem("is_auth_required", isAuthRequired);
  }, [isAuthRequired]);

  useEffect(() => {
    sessionStorage.setItem("is_public", isPublic);
  }, [isPublic]);

  useEffect(() => {
    if (certificateData?.form_io_url) {
      const historyFormData = sessionStorage.getItem("details_form_data");

      if (historyFormData) {
        setIsToggleOn(false);
        fetch(certificateData?.form_io_url)
          .then((resp) => resp.json())
          .then((data) => {
            certificateData?.form_io_url &&
              Formio.createForm(
                document.getElementById("formio"),
                data,
                {}
              ).then((form) => {
                formRef.current = form;
                form.ready.then(() => {
                  form.submission = {
                    data: { ...JSON.parse(historyFormData).data },
                  };
                  form.on("change", formFieldsChanged);
                  form.on("submit", (sub) =>
                    onSubmitHandle(sub, authContext.isLoggedIn)
                  );
                });
              });
            sessionStorage.removeItem("issuer_credential_template_id");
          })
          .catch((error) =>
            console.log("Error while hitting formio url: ", error)
          );
      } else {
        fetch(certificateData?.form_io_url)
          .then((resp) => resp.json())
          .then((data) => {
            certificateData?.form_io_url &&
              Formio.createForm(
                document.getElementById("formio"),
                data,
                {}
              ).then((form) => {
                formRef.current = form;
                form.ready.then(() => {
                  form.submission = {
                    data: isToggleOn
                      ? {
                          ...certificateData?.default_template_variables_data
                            ?.data,
                        }
                      : { ...formData },
                  };
                  form.on("change", formFieldsChanged);
                  form.on("submit", (sub) =>
                    onSubmitHandle(sub, authContext.isLoggedIn)
                  );
                });
              });
            sessionStorage.removeItem("issuer_credential_template_id");
          })
          .catch((error) =>
            console.log("Error while hitting formio url: ", error)
          );
      }
    }
  }, [
    certificateData?.form_io_url,
    authContext.isLoggedIn,
    isToggleOn,
    activeTab,
  ]);

  useEffect(() => {
    authContext.lastVisitedPathHandler(location.pathname);
    const historyCertfificateName = sessionStorage.getItem("certificate_title");
    if (historyCertfificateName) {
      setCertificateTitle(historyCertfificateName);
    }
  });

  return (
    <>
      {breadcrumbItems.length > 0 && (
        <CfBreadcrumb
          breadcrumbItems={breadcrumbItems}
          isToggleOn={isToggleOn}
          setIsToggleOn={setIsToggleOn}
          marginTop={"mt-[0.5rem]"}
          isPostPaid={true}
        />
      )}

      <div className="flex w-full">
        <div className="flex flex-col w-full md:w-2/5 items-center flex-grow bg-cfSecondary h-screen overflow-y-auto">
          <div className="flex space-x-0 md:space-x-4 mb-2 mt-4 bg-gray-300 rounded-xl md:rounded-2xl p-1 mx-1 md:mx-0">
            <button
              className={`px-2 md:px-3 py-1 transition-all duration-200 ${
                activeTab === "basic"
                  ? "bg-white text-black rounded-xl border-none focus:outline-none focus:ring-0"
                  : "bg-gray-300 rounded-lg border-none focus:outline-none focus:ring-0"
              }`}
              onClick={() => handleTabChange("basic")}
            >
              Basic Settings
            </button>
            <button
              className={`px-1 md:px-3 py-1 transition-all duration-200 ${
                activeTab === "advanced"
                  ? "bg-white text-black rounded-xl border-none focus:outline-none focus:ring-0"
                  : "bg-gray-300 rounded-lg border-none focus:outline-none focus:ring-0"
              }`}
              onClick={() => handleTabChange("advanced")}
            >
              Advanced Settings
            </button>
          </div>

          <div className="w-full p-4">
            {activeTab === "basic" && (
              <div className="flex flex-col w-full items-start">
                <label
                  htmlFor="certificateName"
                  className="after:content-['*'] after:text-red-600"
                >
                  Certificate Name
                </label>
                <div className="flex justify-between items-center focus-within:border-4 focus-within:border-cfCertificateNameInputBorder rounded-xl w-full">
                  <div className="flex justify-between items-center border-[1px] rounded-md p-1 w-full">
                    <input
                      type="text"
                      name="certificateName"
                      className="border-none rounded-md w-full focus:outline-none"
                      id="certificateName"
                      onChange={(e) => {
                        const value = e.target.value;
                        setCertificateTitle(value);
                      }}
                      value={certificateTitle}
                    />
                  </div>
                </div>
                {error && <p className="text-red-600">{error}</p>}

                <div id="formio" className="w-full p-2 h-full">
                  <div className="flex justify-center items-center">
                    <CfLoader />
                  </div>
                </div>
              </div>
            )}

            {activeTab === "advanced" && (
              <div className="advanced-settings">
                <div className="flex gap-3 items-start">
                  <input
                    type="checkbox"
                    className="appearance-none mt-[0.245rem] bg-red-600 cursor-pointer rounded checked:bg-blue-500 focus:outline-none "
                    id="isPaymentRequired"
                    checked={isPaymentRequired}
                    // onChange={(e) => setIsPaymentRequired(e.target.checked)}
                    onChange={handlePaymentCheckboxChange}
                  />
                  <label
                    htmlFor="isPaymentRequired"
                    className="items-start text-start"
                  >
                    Enable paid verification
                  </label>
                </div>
                {isPaymentRequired && (
                  <div className="mb-4">
                    <label className="text-gray-600">
                      Verification Amount to be paid
                    </label>
                    <div className="flex items-center rounded-md bg-white pl-3 outline outline-1 -outline-offset-1 outline-gray-300 has-[input:focus-within]:outline has-[input:focus-within]:outline-2 has-[input:focus-within]:-outline-offset-2 has-[input:focus-within]:outline-indigo-600">
                      <div className="shrink-0 select-none text-base text-gray-500 sm:text-sm/6 mr-2.5">
                        ₹
                      </div>
                      <input
                        type="text"
                        name="price"
                        id="price"
                        class="block min-w-0 grow py-1.5 pl-1 pr-3 text-base text-gray-800 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6 border border-gray-500"
                        placeholder="0.00"
                        value={verificationCost}
                        onChange={handleVerificationCostChange}
                      />
                    </div>

                    {!verificationCost.trim() && (
                      <p className="text-red-600 mt-1">
                        Verification amount is required.
                      </p>
                    )}
                  </div>
                )}

                <div className="mb-4">
                  <label
                    htmlFor="certificateRedirectURL"
                    className="text-gray-600"
                  >
                    Certificate Redirect URL
                  </label>
                  <input
                    id="certificateRedirectURL"
                    name="certificateRedirectURL"
                    type="text"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-lg"
                    onChange={(e) => setCertificateRedirectURL(e.target.value)}
                    value={certificateRedirectURL}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="isAuthRequired" className="text-gray-600">
                    Is Authentication Required
                  </label>
                  <select
                    id="isAuthRequired"
                    defaultValue="no"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                    onChange={(e) =>
                      setIsAuthRequired(e.target.value === "yes")
                    }
                    value={isAuthRequired ? "yes" : "no"}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label htmlFor="isPublic" className="text-gray-600">
                    Is Public
                  </label>
                  <select
                    id="isPublic"
                    defaultValue="no"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                    onChange={(e) => setIsPublic(e.target.value === "yes")}
                    value={isPublic ? "yes" : "no"}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <button
                  className="submit-btn mt-6 px-4 py-2 bg-[#3538CD] text-cfSecondary w-full text-white rounded-lg"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="w-full mx-1 p-3">
          <div className="w-full">
            <div
              className="flex flex-col flex-shrink max-h-[78vh]"
              id="certificate_container"
            >
              <CfLoader />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificateDetails;
